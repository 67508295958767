import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, visit }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="Icon"/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
            <div>
                <button className="px-5 py-1 mt-2 rounded-2 bg-white text-violet-100">
                    <a href={visit} target="_blank" rel="noreferrer" className="text-decoration-none text-secondary">Check It Out</a>
                </button>
            </div>
        </div>
      </div>
    </Col>
  )
}
